<template>
    <div style="height: 100%; width: 100%;">
        <Header/>
        <router-view></router-view>
        <ContactBanner v-if="!['/contact'].includes(this.$route.path)"/>
        <Partners/>
        <Footer/>
        <a href="javascript: void(0);" @click="goToTop()" class="topButton" v-if="showTopButton"><ArrowUpIcon w="30" h="30"/></a>
    </div>
</template>
<style scoped>
a.topButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: var(--lexoh-color-primary);
    color: var(--lexoh-color-base);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<script lang="ts">
    import { defineComponent } from 'vue';
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import ContactBanner from '@/components/ContactBanner.vue';
    import Partners from '@/components/Partners.vue';
    import ArrowUpIcon from 'vue-ionicons/dist/md-arrow-up.vue'
    import { useI18n } from 'vue-i18n';
    var translate;
    export default defineComponent({
        name: 'App',
        components: { Header, Footer, ContactBanner, Partners, ArrowUpIcon },
        setup() {
        },
        data() {
            return {
                showTopButton: false
            }
        },
        mounted()
        {
            this.createTranslator();
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            createTranslator()
            {
                var { t } = useI18n({
                    inheritLocale: false,
                    useScope: 'global',
                    locale: 'fr'
                });
                translate = t;
            },
            handleScroll (event) {
                if (window.scrollY >= 300) {
                    this.showTopButton = true;
                } else if(window.scrollY == 0) {
                    this.showTopButton = false;
                }
            },
            goToTop() {
                window.scroll({top: 0, left: 0, behavior: 'smooth'});
            }
        }
    });
</script>