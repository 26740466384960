<template>
    <footer>
        <div class="container" style="justify-content: start !important;">
            <div class="main_section">
                <b-row>
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <h6>LEXOH</h6>
                        <hr/>
                        <p>{{ t('footer_desc') }}</p>
                        <br/>
                        <a href="/assets/PCI_COC.pdf" target="_BLANK"><img src="/assets/badge_pci_dss_compliant.png" alt="PCI DSS Compliant" style="width: 75% !important;"/></a>
                        <p>
                            {{ t("Certificate Number") }}: CSN7041<br/>
                            Classification: SAQ D<br/>
                            {{ t("pci_valid_till") }}
                        </p>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <h6>{{ t('Work Hours') }}</h6>
                        <hr/>
                        <p>8:00 à 17:00 {{ t('monday_to_friday') }}</p>
                        <p>{{ t('footer_support') }}</p>
                        <a href="tel:+15142283827" class="mediumbtn">{{ t('CALL US TODAY') }}</a>
                        <br/>
                        <br/>
                        <br/>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <h6>{{ t('Quick Links') }}</h6>
                        <hr/>
                        <ul>
                            <li><a href="/" v-on:click.prevent="goToRoute('/');">{{ t('home') }}</a></li>
                            <li>
                                <a href="#">{{ t('solutions') }}</a>
                                <ul>
                                    <li><a href="/access-control-solutions" v-on:click.prevent="goToRoute('/access-control-solutions');">{{ t('Access Control Solutions') }}</a></li>
                                    <li><a href="/parking-solutions" v-on:click.prevent="goToRoute('/parking-solutions');">{{ t('Parking Solutions') }}</a></li>
                                    <li><a href="/payment-systems" v-on:click.prevent="goToRoute('/payment-systems');">{{ t('Payment Systems') }}</a></li>
                                    <li><a href="/communication-systems" v-on:click.prevent="goToRoute('/communication-systems');">{{ t('Communication Systems') }}</a></li>
                                    <li><a href="/security-cameras" v-on:click.prevent="goToRoute('/security-cameras');">{{ t('Security Cameras') }}</a></li>
                                    <li><a href="/lakes-protection" v-on:click.prevent="goToRoute('/lakes-protection');">{{ t('Protection of Lakes') }}</a></li>
                                </ul>
                            </li>
                            <li><a href="/faq" v-on:click.prevent="goToRoute('/faq');">{{ t('faq') }}</a></li>
                            <li><a href="/contact" v-on:click.prevent="goToRoute('/contact');">{{ t('contact') }}</a></li>
                        </ul>
                        <br/>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <h6>{{ t('Legal Links') }}</h6>
                        <hr/>
                        <ul>
                            <li><a href="/terms" v-on:click.prevent="goToRoute('/terms');">{{ t('Terms of service') }}</a></li>
                            <li><a href="/privacy" v-on:click.prevent="goToRoute('/privacy');">{{ t('Privacy policy') }}</a></li>
                            <li v-if="false"><a href="/cookie" v-on:click.prevent="goToRoute('/cookie');">{{ t('Cookie policy') }}</a></li>
                        </ul>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="copyright">
            <div class="container" style="justify-content: start !important;">
                Copyright &copy; 2023-2024 Lexoh Inc. {{ t('All Rights Reserved') }}
            </div>
        </div>
    </footer>
</template>
<style scoped>
    footer {
        background-color: #202020;
    }
    footer h6 {
        font-family: "Proxima Nova",-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 17px;
        text-transform: none;
        letter-spacing: 0;
        line-height: 30px;
        color: white;
    }
    footer p {
        font-size: 15px;
        color: #848991;
    }
    footer hr {
        height: 3px;
        width: 10%;
        background: #848991;
    }
    footer .copyright {
        width: 100%;
        min-height: 66px !important;
        display: flex;
        align-items: center;
        justify-content: start;
        color: #676767;
        background-color: #1a1a1a !important;
    }
    footer .main_section {
        padding: 70px 0px 70px 0px;
    }
    footer ul {
        list-style: none !important;
        margin: 0;
        padding: 0;
    }
    footer ul li {
        margin-left: 20px;
        position: relative;
    }
    footer ul li > a {
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        color: var(--lexoh-color-text02) !important;
    }
    footer ul li > a:hover {
        margin-left: 10px;
        color: var(--lexoh-color-primary) !important;
    }
</style>
<script lang="ts">
    import { defineComponent } from 'vue';
    import { useI18n } from 'vue-i18n'
    export default defineComponent({
        setup()
        {
            const { t } = useI18n({
                inheritLocale: true,
                useScope: 'global'
            });
            return { t };
        },
        methods: {
            goToTop() {
                window.scroll({top: 0, left: 0});
            },
            goToRoute(routeName: any)
            {
                this.$router.push(routeName);
                this.goToTop();
                return false;
            }
        }
    });
</script>