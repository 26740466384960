import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16b007dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%","width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ContactBanner = _resolveComponent("ContactBanner")!
  const _component_Partners = _resolveComponent("Partners")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ArrowUpIcon = _resolveComponent("ArrowUpIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_router_view),
    (!['/contact'].includes(this.$route.path))
      ? (_openBlock(), _createBlock(_component_ContactBanner, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Partners),
    _createVNode(_component_Footer),
    (_ctx.showTopButton)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: "javascript: void(0);",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToTop())),
          class: "topButton"
        }, [
          _createVNode(_component_ArrowUpIcon, {
            w: "30",
            h: "30"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}