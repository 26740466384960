<template>
    <section class="our_partners" v-if="false">
        <div class="container">
            <div style="width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
                <p class="title">{{ t('OUR PARTNERS') }}</p>
                <p class="subtitle">{{ t('THEY LOVE TO DO BUSINESS WITH US') }}</p>
            </div>
            <Splide aria-label="" :options="splideOptions">
                <SplideSlide v-for="(item,index) in partners" :key="index">
                    <img :src="item" alt="">
                </SplideSlide>
            </Splide>
        </div>
    </section>
    <section class="our_partners">
        <div class="container">
            <div style="width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
                <p class="title">{{ t('OUR CUSTOMERS') }}</p>
                <p class="subtitle">{{ t('THEY TRUST LEXOH') }}</p>
            </div>
            <Splide aria-label="" :options="splideOptions2">
                <SplideSlide v-for="(item,index) in customers" :key="index">
                    <img :src="item" alt="">
                </SplideSlide>
            </Splide>
        </div>
    </section>
</template>
<style scoped>
.our_partners {
    width: 100%;
    padding: 50px 0;
    background-color: var(--lexoh-color-base);
}
.our_partners .title {
    color: var(--lexoh-color-primary);
    font-family: "Work Sans",-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif;
    font-weight: 600;
    text-transform: none;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
}
.our_partners .subtitle {
    color: var(--lexoh-color-text04);
    font-family: "Work Sans",-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif;
    font-weight: 700;
    font-size: 42px;
    line-height: 54px;
    text-align: center;
}
.splide__slide img {
    margin: 10px;
    max-height: 68px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n'
import '@splidejs/vue-splide/css';
export default defineComponent({
    name: 'PartnersComponent',
    components: {},
    setup()
    {
        const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
        });
        return { t };
    },
    data()
    {
        return {
            splideOptions: {
                rewind: true,
                cover: false,
                autoWidth: true,
                height: 80,
                autoplay: true,
                interval: 3000,
                speed: 1000,
                type: 'loop',
                arrows: false,
                pagination: false,
                perPage: 3
            },
            splideOptions2: {
                rewind: true,
                cover: false,
                autoWidth: true,
                height: 80,
                autoplay: true,
                interval: 3000,
                speed: 1000,
                type: 'loop',
                arrows: false,
                pagination: false,
                perPage: 3
            },
            partners: [
                '/assets/partners/pax.jpg',
                '/assets/partners/gts.png',
                '/assets/partners/moneris.jpg',
                '/assets/partners/payfacto.webp',
                '/assets/partners/ingram.png',
                '/assets/partners/reolink.png'
            ],
            customers: [
                '/assets/customers/abenaki.webp',
                '/assets/customers/jpomerleau.jpg',
                '/assets/customers/villetemiscouata.png',
                '/assets/customers/sepaq.png',
                '/assets/customers/saintjeandelalande.png',
                '/assets/customers/stJusteduLac.png',
                '/assets/customers/cdydc.jpg',
                '/assets/customers/packington.jpg',
                '/assets/customers/degelis.png',
                '/assets/customers/temiscouata.jpg',
                '/assets/customers/clicknpark.svg',
            ]
        }
    }
});
</script>
