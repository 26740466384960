// Copyright 2023 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: () => import('@/views/HomePage.vue')
	},
	{
		path: '/access-control-solutions',
		component: () => import('@/views/services/AccessControlPage.vue')
	},
	{
		path: '/parking-solutions',
		component: () => import('@/views/services/ParkingSolutionsPage.vue')
	},
	{
		path: '/payment-systems',
		component: () => import('@/views/services/PaymentSystemsPage.vue')
	},
	{
		path: '/communication-systems',
		component: () => import('@/views/services/CommunicationSystemsPage.vue')
	},
	{
		path: '/security-cameras',
		component: () => import('@/views/services/SecurityCamerasPage.vue')
	},
	{
		path: '/lakes-protection',
		component: () => import('@/views/services/LakesProtectionPage.vue')
	},
	{
		path: '/faq',
		component: () => import('@/views/FaqPage.vue')
	},
	{
		path: '/contact',
		component: () => import('@/views/ContactPage.vue')
	},
	{
		path: '/terms',
		component: () => import('@/views/TermsPage.vue')
	},
	{
		path: '/privacy',
		component: () => import('@/views/PrivacyPage.vue')
	},
	{
		path: '/cookie',
		component: () => import('@/views/CookiePage.vue')
	},
	{
		path: '/lexoh-manager',
		component: () => import('@/views/products/LexohPage.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router