export default {
    "home": "Accueil",
    "solutions": "Solutions",
    "faq": "FAQ",
    "contact": "Contact",
    "Access Control Solutions": "Solutions de contrôle d’accès",
    "Access Control": "Contrôle d’accès",
    "Parking Solutions": "Solutions de stationnement",
    "Payment Systems": "Systèmes de paiements",
    "Communication Systems": "Systèmes de communication",
    "Security Cameras": "Caméras de sécurité",
    "Protection of Lakes": "Protection des lacs",
    "footer_desc": "Nous fournissons des solutions logicielles de nouvelle génération pour le stationnement et le contrôle d'accès. Notre objectif est de fournir des fonctionnalités de qualité supérieure qui sont intuitives à utiliser.",
    "Monday": "Lundi",
    "Friday": "Vendredi",
    "Work Hours": "Heures d’affaires",
    "footer_support": "Notre équipe de support et de vente est disponible pour répondre à vos questions.",
    "CALL US TODAY": "APPELEZ-NOUS",
    "Quick Links": "Liens rapides",
    "Legal Links": "Liens Juridiques",
    "Terms of service": "Conditions d’utilisation",
    "Privacy policy": "Politique de confidentialité",
    "Cookie policy": "Politique de cookies",
    "All Rights Reserved": "Tous droits réservés",
    "OUR PARTNERS": "NOS PARTENAIRES",
    "THEY LOVE TO DO BUSINESS WITH US": "ILS ADORENT FAIRE AFFAIRE AVEC NOUS",
    "contact_banner_text": "Cherchez-vous des experts de haut niveau et de la technologie de pointe?",
    "CONTACT US": "CONTACTEZ-NOUS",
    "home_hero_title": "Solutions logicielles de nouvelle génération pour le contrôle d’accès",
    "home_hero_desc": "Des solutions de qualité inégalée adaptées à vos besoins pour réduire les coûts et les pertes de temps. Notre objectif est de fournir des fonctionnalités de haute qualité qui sont intuitives à utiliser.",
    "Learn more": "En savoir plus",
    "WHY CHOOSE US?": "POURQUOI NOUS CHOISIR?",
    "SECURITY EXPERTS": "EXPERTS EN SÉCURITÉ",
    "why_choose_us_desc1": "Chez LEXOH, nous sommes spécialisés dans des solutions de sécurité globales englobant à la fois les domaines numériques et physiques. Avec une équipe dévouée de professionnels, nous nous engageons à protéger vos biens et environnements en utilisant des technologies de pointe conformes aux normes de sécurité les plus élevées.",
    "why_choose_us_desc2": "Fort de nombreuses années d’expérience, nous avons perfectionné notre expertise dans les domaines complexes de la cybersécurité et de la mise en œuvre de la sécurité dans le monde réel. Nos solutions ne sont pas seulement des produits ; ce sont des outils stratégiques conçus pour renforcer votre posture de sécurité.",
    "ABOUT US": "À PROPOS DE NOUS",
    "Driven by Vision, Defined by Values": "Portés par une vision, définis par des valeurs",
    "about_us_desc": "Nous sommes un centre renommé d’ingénieurs logiciels professionnels et de techniciens de terrain. Forts d’une expérience combinée dépassant quatre décennies dans l’industrie du stationnement et du contrôle d’accès, nous avons entrepris un voyage pour établir notre start-up pionnière. Notre mission inébranlable est d’annoncer des solutions logicielles inégalées qui dépassent les normes industrielles conventionnelles, tout en restant fermement ancrées dans nos principes fondamentaux.",
    "OUR SERVICES": "NOS SERVICES",
    "TRANSFORMING NEEDS INTO SOLUTIONS": "TRANSFORMER LES BESOINS EN SOLUTIONS",
    "service_1_desc": "La sécurité est une priorité essentielle pour les entreprises de toutes sortes et de toutes tailles. Protéger les actifs de votre entreprise est crucial, en particulier si vous manipulez des équipements coûteux et des informations confidentielles.",
    "service_2_desc": "La gestion du stationnement est une excellente solution pour générer un revenu passif ou sécuriser qui est autorisé à entrer sur votre site de stationnement.<br/><br/><br/>",
    "service_3_desc": "Les caméras de sécurité vous offrent une sécurité supplémentaire, dissuadent les personnes de commettre des crimes et vous fournissent les preuves dont vous avez besoin pour votre assurance.<br/><br/>",
    "service_4_desc": "Préservez la pureté de nos lacs, une lessive à la fois. Optez pour des stations de lavage équipées de systèmes de pointe et de contrôles d’accès pour éviter toute contamination. Protéger nos eaux, c’est préserver notre avenir.",
    "service_5_desc": "Élevez votre communication avec nos systèmes d’interphonie avancés alimentés par la technologie SIP. Nous comblons l’écart entre vous et vos précieux utilisateurs, garantissant des interactions d’une clarté cristalline.",
    "service_6_desc": "Le futur des transactions sans tracas avec nos systèmes de paiement. Des bornes de stationnement simplifiées aux stations de lavage pratiques, donnez à vos clients le pouvoir de solutions de paiement en libre-service faciles et sécurisées.",
    "IDEATION TO OPERATIONS": "IDÉATION AUX OPÉRATIONS",
    "WHAT MAKES US DIFFERENT": "CE QUI NOUS DISTINGUE",
    "makes_us_different_desc1": "Chez LEXOH, ce qui nous distingue, c’est notre passion inébranlable en tant qu’ingénieurs logiciels dédiés à la création de solutions inégalées pour notre industrie. Notre engagement envers l’excellence nous pousse à développer des logiciels qui se démarquent nettement des autres. Chaque ligne de code est le témoignage de notre quête de robustesse et de rapidité, méticuleusement conçue en utilisant des langages de programmation natifs et des technologies de pointe. Nous adoptons la méthodologie agile, favorisant la collaboration, l’adaptabilité et les progrès rapides. À chaque projet, nous visons rien de moins que l’extraordinaire, en fournissant des logiciels qui non seulement répondent aux attentes, mais les dépassent, établissant de nouvelles normes en matière d’innovation et de performances.",
    "makes_us_different_desc2": "Nous comprenons que la technologie doit être aussi intuitive que puissante. C’est pourquoi nous allons au-delà des attentes pour nous assurer que chaque composant que nous concevons est convivial, qu’il soit accessible depuis un ordinateur de bureau ou un appareil mobile. Notre approche axée sur l’utilisateur imprègne tous les aspects de notre processus de développement logiciel.",
    "Gallery": "Gallerie",
    "Modular equipment": "Équipement modulaire",
    "modular_equipment_desc": "Nous nous assurons que tous nos équipements restent modulaires afin que vous puissiez apporter les vôtres et effectuer des mises à niveau à tout moment.",
    "modular_equipment_title1": "Affichage écran HD",
    "modular_equipment_title2": "Lecteur RFID ultra-haute fréquence",
    "modular_equipment_title3": "Lecteur de codes-barres 2D/QR",
    "modular_equipment_title4": "Lecteur de cartes NFC/HID",
    "modular_equipment_title5": "Terminal de paiement",
    "modular_equipment_title6": "Interphone SIP",
    "modular_equipment_title7": "Caméra de sécurité",
    "modular_equipment_title8": "Serrure de porte magnétique",
    "modular_equipment_desc1": "Nous associons notre contrôleur de portail à un affichage HD 1080p, fini l'affichage LCD monochrome pixelisé.",
    "modular_equipment_desc2": "Ajoutez un lecteur RFID à votre infrastructure et commencez à identifier les véhicules sans aucune interaction humaine à l'aide d'un transpondeur.",
    "modular_equipment_desc3": "Même s'il s'agit d'une technologie ancienne, elle a fait ses preuves et les utilisateurs y sont habitués. Vous pouvez donc scanner vos coupons/billets imprimés.",
    "modular_equipment_desc4": "L'accès sans contact aux entrées et aux sorties à l'aide d'une carte d'accès est un moyen rapide et facile d'identifier les utilisateurs.",
    "modular_equipment_desc5": "Nous utilisons un terminal de paiement basé sur Android de nouvelle génération avec une application personnalisée qui offre bien plus que les terminaux traditionnels.",
    "modular_equipment_desc6": "Les interphones sont utiles, ils peuvent offrir un support instantané à vos utilisateurs et permettre l'ouverture à distance d'une porte/porte sans accorder à vos employés un accès complet à la gestion.",
    "modular_equipment_desc7": "Protégez votre investissement contre les actes de vandalisme ou les accidents grâce à des caméras de sécurité et libérez-vous de toute responsabilité.",
    "modular_equipment_desc8": "Les serrures de porte magnétiques utilisent de l'énergie électromagnétique pour sécuriser les portes d'entrée et de sortie, elles peuvent être utilisées pour restreindre l'accès des employés et des utilisateurs par zones.",
    "acces_control_title": "SOLUTIONS DE<br/>CONTRÔLE D’ACCÈS",
    "acces_control_title2": "SOLUTIONS RÉSIDENTIELLES ET COMMERCIALES",
    "Elevating Access Control": "Élévation du contrôle d’accès",
    "acces_control_desc1": "Notre objectif est de fournir des fonctionnalités de haute qualité qui sont intuitivement conviviales. Nous investissons dans la recherche et le développement pour offrir des solutions axées sur la technologie de pointe, offrant ainsi une expérience utilisateur fluide et agréable.",
    "acces_control_desc2": "Pour les espaces résidentiels, nos solutions sur mesure garantissent que les propriétaires et les résidents vivent une sensation accrue de sécurité et de commodité. Des systèmes d’entrée intelligents permettant la gestion à distance de l’accès aux intégrations de surveillance avancées, nous offrons un mélange harmonieux de technologie et de tranquillité d’esprit.",
    "acces_control_desc3": "Dans le domaine des espaces commerciaux, nos solutions redéfinissent le contrôle d’accès en s’harmonisant parfaitement avec le rythme et les exigences des entreprises modernes. Que ce soit pour optimiser l’accès des employés, la gestion des visiteurs ou la protection des zones sensibles, notre gamme de solutions est conçue pour s’adapter et améliorer les protocoles de sécurité tout en favorisant la fluidité opérationnelle.",
    "acces_control_feature_title1": "Conception axée sur l'utilisateur",
    "acces_control_feature_title2": "Technologie de pointe",
    "acces_control_feature_title3": "Sécurité renforcée",
    "acces_control_feature_title4": "Adaptabilité personnalisée",
    "acces_control_feature_title5": "Accessibilité à distance",
    "acces_control_feature_desc1": "Notre solution est conçue en pensant à l’utilisateur, en priorisant des interfaces intuitives et une facilité d’utilisation, garantissant une expérience fluide pour tous les utilisateurs.",
    "acces_control_feature_desc2": "Nous repoussons constamment les limites de la technologie, en incorporant les dernières avancées pour nous assurer que notre solution demeure à l’avant-garde de l’innovation.",
    "acces_control_feature_desc3": "Notre solution intègre des fonctionnalités de sécurité avancées pour protéger les espaces résidentiels et commerciaux, offrant la tranquillité d’esprit et une protection contre les menaces potentielles.",
    "acces_control_feature_desc4": "Des maisons résidentielles complexes aux environnements commerciaux, notre solution s’adapte de manière transparente, offrant des configurations personnalisables pour répondre à des besoins uniques.",
    "acces_control_feature_desc5": "Profitez de la commodité de gérer et surveiller l’accès à distance, vous offrant un contrôle à portée de main, où que vous soyez.",
    "parking_solutions_title": "Solutions de<br/>Stationnement",
    "parking_solutions_title2": "NOTRE MISSION & NOTRE VISION",
    "Stress-free solutions.": "Solutions sans stress.",
    "parking_solutions_desc": "Les entreprises économisent un temps administratif précieux et prennent de meilleures décisions lorsqu’elles disposent des bonnes informations en temps réel. C’est pourquoi chez Lexoh, nous ne prévoyons pas simplement de créer de nouveaux produits équivalents, mais plutôt de concevoir des produits réfléchis et faciles à utiliser.",
    "parking_solutions_feature_title1": "Gérez à distance grâce aux capacités mobiles en premier lieu, tandis que la fiabilité basée sur le cloud maintient une expérience cohérente pour vos clients.",
    "parking_solutions_feature_title2": "Offrez une expérience high-tech et sans contact adaptée au mode de vie du 21e siècle de vos résidents et de leurs invités.",
    "parking_solutions_feature_title3": "Créez une sensation de facilité et d'efficacité avec notre approche simple pour le contrôle opérationnel à volume élevé et la responsabilité des revenus.",
    "parking_solutions_feature_title4": "Obtenez une vue d'ensemble des opérations sur l'ensemble de votre campus universitaire avec des contrôles complets qui simplifient la gestion et offrent un accès à tout moment et en tout lieu.",
    "parking_solutions_feature_desc1": "",
    "parking_solutions_feature_desc2": "",
    "parking_solutions_feature_desc3": "",
    "parking_solutions_feature_desc4": "",
    "payment_systems_title": "SYSTÈMES DE<br/>PAIEMENT",
    "payment_systems_title2": "L’INNOVATION MÊME AVEC LES PAIEMENTS",
    "Unprecedented features": "Fonctionnalités sans précédent",
    "payment_systems_desc": "Nous sommes fiers d’être des pionniers de l’industrie en offrant une solution de terminal de paiement de nouvelle génération pour le stationnement. Grâce à notre engagement envers l’excellence et notre volonté de repousser les limites, nous avons révolutionné le secteur. En réduisant les coûts et en élargissant la gamme de fonctionnalités, nous avons transformé le paysage des solutions de stationnement.",
    "payment_systems_feature_title1": "Identification basée sur un code PIN.",
    "payment_systems_feature_title2": "Identification par QR code / code-barres.",
    "payment_systems_feature_title3": "Identification par carte de crédit/débit.",
    "payment_systems_feature_title4": "Renouvellement d'abonnement.",
    "payment_systems_feature_title5": "Achat de forfait hebdomadaire/mensuel.",
    "payment_systems_feature_desc1": "Les utilisateurs peuvent entrer un numéro d’identification pour accéder.",
    "payment_systems_feature_desc2": "Notre terminal prend en charge la numérisation de codes QR et de codes-barres pour une identification rapide et précise.",
    "payment_systems_feature_desc3": "Les utilisateurs peuvent facilement utiliser leurs cartes de crédit ou de débit pour une identification sans effort, réduisant ainsi l’utilisation de papier.",
    "payment_systems_feature_desc4": "Notre terminal offre l’option pratique de renouveler les abonnements existants.",
    "payment_systems_feature_desc5": "Les utilisateurs ont la flexibilité d’acquérir des forfaits de stationnement hebdomadaires ou mensuels.",
    "communication_systems_title": "SYSTÈMES DE<br/>COMMUNICATION",
    "communication_systems_title2": "CONNECTE ET AMÉLIORE LA SÉCURITÉ",
    "Advanced Intercom Solutions": "Solutions d’interphonie avancées",
    "communication_systems_desc1": "Un système d’interphonie est un outil essentiel pour garantir la sécurité et la gestion efficace des installations de stationnement. Avec l’augmentation du nombre de véhicules et d’utilisateurs dans les zones de stationnement, disposer d’un système de communication fiable devient crucial. Ce système permet aux utilisateurs de demander de l’aide en cas de besoin et permet aux opérateurs de gérer les entrées et les sorties de manière transparente. Notre système d’interphonie de stationnement propose des fonctionnalités avancées qui le positionnent en tête de l’industrie.",
    "communication_systems_desc2": "Nous nous engageons à offrir une solution complète qui répond aux besoins actuels en matière de sécurité, de contrôle d’accès et de gestion du stationnement. En choisissant notre système d’interphonie, vous optez pour une solution de communication et de sécurité de premier ordre. Notre engagement réside dans la fourniture de produits de qualité supérieure qui répondent aux exigences de la sécurité et de la gestion moderne du stationnement. Faites confiance à notre expertise et bénéficiez d’une solution complète et fiable pour votre installation de stationnement.",
    "communication_systems_feature_title1": "Communication claire et fiable",
    "communication_systems_feature_title2": "Intégration avec d'autres systèmes de sécurité",
    "communication_systems_feature_title3": "Contrôle d'accès avancé",
    "communication_systems_feature_title4": "Facilité d'installation et d'utilisation",
    "communication_systems_feature_title5": "Fiabilité et durabilité",
    "communication_systems_feature_desc1": "",
    "communication_systems_feature_desc2": "",
    "communication_systems_feature_desc3": "",
    "communication_systems_feature_desc4": "",
    "communication_systems_feature_desc5": "",
    "security_cameras_title": "CAMÉRAS DE<br/>SÉCURITÉ",
    "security_cameras_title2": "PROTÉGER CHAQUE ANGLE, SÉCURISER CHAQUE INSTANT",
    "Security with Surveillance Cameras": "Sécurité avec caméras de surveillance",
    "security_cameras_desc1": "Entrez dans un monde de sécurité et de vigilance inégalées avec nos solutions de caméras de surveillance avancées. Chez LEXOH, nous comprenons que la protection de vos locaux est primordiale, que ce soit chez vous ou dans votre entreprise. Nos caméras de sécurité de pointe offrent une clarté, une couverture et une tranquillité d’esprit inégalées. Grâce à la surveillance en temps réel et à la capacité d’enregistrement haute résolution, vous pouvez être sûr que nos caméras servent d’œil vigilant, dissuadant les menaces potentielles et fournissant des informations précieuses quand elles comptent le plus. Rehaussez vos mesures de sécurité avec une technologie qui ne se contente pas d’enregistrer des moments, elle assure votre sécurité, de jour comme de nuit.",
    "security_cameras_desc2": "Au-delà de la simple capture d’images, nos systèmes de caméras de surveillance sont conçus pour vous fournir des informations en temps réel et un contrôle. Grâce aux capacités d’accès à distance, vous pouvez surveiller votre propriété de n’importe où, à tout moment. Gagnez en confiance et restez connecté à votre environnement, que vous soyez au travail, en vacances ou simplement loin de votre propriété. Notre engagement envers votre sécurité va au-delà du matériel, il s’agit de vous fournir un écosystème de sécurité complet qui vous apporte la tranquillité d’esprit et une protection inégalée.",
    "security_cameras_feature_title1": "Révolutionner le contrôle d'accès : la technologie ALPR (Automatic License Plate Recognition)",
    "security_cameras_feature_title2": "Surveillance alimentée par l'IA (intelligence artificielle)",
    "security_cameras_feature_desc1": "Découvrez l’avenir du contrôle d’accès avec notre technologie de reconnaissance automatique des plaques d’immatriculation (ALPR).",
    "security_cameras_feature_desc2": "Notre technologie avancée offre non seulement une identification en temps réel, mais détecte également les moments clés, vous fournissant une compréhension complète des activités au sein de vos locaux.",
    "lake_protection_title": "PROTECTION<br/>DES LACS",
    "lake_protection_title2": "FIERS DE CONTRIBUER À LA PROTECTION DE L’ENVIRONNEMENT",
    "Preventing Lakes Contamination": "Prévention de la contamination des lacs",
    "lake_protection_desc1": "Chez LEXOH, nous sommes fiers de contribuer à la préservation de l’environnement grâce à notre solution révolutionnaire de station de lavage de bateaux. Avec un engagement profond envers la prévention de la contamination interlacustre, nous avons développé une approche de pointe qui garantit non seulement la propreté des bateaux, mais aussi la sauvegarde de l’équilibre délicat de nos écosystèmes aquatiques.",
    "lake_protection_desc2": "Notre solution de station de lavage de bateaux va au-delà du concept traditionnel d’entretien des bateaux. En obligeant les utilisateurs à laver leurs bateaux avant d’accéder à l’eau, nous atténuons efficacement les risques d’introduction de contaminants nocifs, d’espèces envahissantes et de polluants dans nos lacs et rivières. Cette mesure proactive agit comme une puissante défense contre les perturbations écologiques potentielles causées par les embarcations non contrôlées.",
    "lake_protection_desc3": "Grâce à notre système innovant, les propriétaires de bateaux ont non seulement un accès pratique aux aventures aquatiques, mais ils sont également responsabilisés en tant que gardiens de nos ressources naturelles. Le simple acte de laver son bateau devient une étape essentielle dans la préservation de la pureté de nos plans d’eau, favorisant une coexistence harmonieuse entre les loisirs et la durabilité environnementale.",
    "lake_protection_desc4": "Rejoignez-nous dans notre mission de protéger et de préserver nos précieux cours d’eau. En adoptant notre solution de station de lavage de bateaux, vous faites une différence tangible dans la lutte contre la contamination interlacustre, garantissant que la beauté et la vitalité de nos écosystèmes aquatiques perdurent pour les générations à venir.",
    "faq_title1": "Qu’est-ce que le moteur LEXOH?",
    "faq_title2": "Puis-je faire migrer mon infrastructure de stationnement existante vers les solutions LEXOH?",
    "faq_title3": "Puis-je utiliser mes propres cartes RFID avec LEXOH?",
    "faq_desc1": "Le moteur LEXOH constitue le cadre fondamental de notre système de contrôle d'accès, répondant à un large éventail d'applications englobant le contrôle d'accès commercial, résidentiel et la gestion du stationnement. Ce moteur polyvalent vous permet d'administrer méticuleusement votre base d'utilisateurs et votre clientèle, en exerçant un contrôle nuancé sur leurs privilèges d'accès dans des zones distinctes, tout en offrant la flexibilité de structures tarifaires personnalisées.",
    "faq_desc2": "Notre système s'intègre de manière transparente à un large éventail de barrières, de lecteurs et d'interphones, garantissant ainsi la compatibilité entre diverses configurations. Cependant, il pourrait s’avérer nécessaire de mettre à jour les composants des stations de paiement. N’hésitez pas à nous contacter pour organiser une visite sur place. Cela nous permettra d’approfondir vos besoins spécifiques et de faciliter le développement d’une solution de migration complète adaptée à vos besoins.",
    "faq_desc3": "Le moteur LEXOH est intégré au décodage des cartes MIFARE Classic 1K, renforcé par un algorithme de cryptage propriétaire qui améliore les mesures de sécurité. Notre engagement à protéger votre contrôle d'accès est illustré par cette approche de cryptage spécialisée.<br/><br/>Si vous avez besoin d'un alignement entre vos cartes d'accès existantes et nos protocoles de cryptage, nous offrons le service de reprogrammation. Pour des informations complètes, n’hésitez pas à nous contacter via nos coordonnées fournies. Votre sécurité est notre priorité.",
    "Our support team will assist you with any questions.": "Notre équipe d'assistance<br/>vous aidera pour toutes vos questions.",
    "Telephone": "Téléphone",
    "Address": "Adresse",
    "TERMS OF SERVICE": "CONDITIONS D'UTILISATION",
    "PRIVACY POLICY": "POLITIQUE DE CONFIDENTIALITÉ",
    "OUR CUSTOMERS": "NOS CLIENTS",
    "THEY TRUST LEXOH": "ILS FONT CONFIANCE À LEXOH",
    "products": "Produits",
    "Cloud-based": "Basé sur le cloud",
    "Intercoms": "Interphones",
    "Video Security": "Sécurité vidéo",
    "Barriers": "Barrières",
    "Housings & Kiosks": "Boîtiers et kiosques",
    "Overview": "Aperçu",
    "Platform": "Platforme",
    "Features": "Caractéristiques",
    "Integration": "Intégration",
    "Security": "Sécurité",
    "Mobile": "Mobile",
    "High Availability": "Haute disponibilité",
    "Backups": "Sauvegardes",
    "Video on Demand": "Vidéo sur demande",
    "Data retention": "Conservation des données",
    "Door Controllers": "Contrôleurs de porte",
    "RFID Card Reader": "Lecteur de carte RFID",
    "QR Reader": "Lecteur QR",
    "Garage Door Controller": "Contrôleur de porte de garage",
    "Barrier Controller": "Contrôleur de barrière",
    "Numeric pinpad": "Clavier NIP numérique",
    "Kiosk & Door Intercom": "Kiosque et interphone de porte",
    "SIP Server": "Serveur SIP",
    "Cameras": "Caméras",
    "Cloud camera Server": "Serveur de caméra cloud",
    "AI vehicle & person detection": "Véhicules & personnes par IA",
    "Event tracking": "Suivi des événements",
    "Facial recognition": "Reconnaissance faciale",
    "Lane Barrier": "Barrière de voie",
    "Articulated Lane Barrier": "Barrière de voie articulée",
    "Double Lane Barrier": "Barrière à double voie",
    "Command Box": "Boîtier de commande",
    "Payment Box": "Boîtier de paiement",
    "Kiosk": "Kiosque",
    "AI": "IA",

    "lake_page_subtitle": "Nous sommes fiers de contribuer à la préservation de l’environnement grâce à notre solution révolutionnaire de station de lavage de bateaux.",
    "Avantages": "Avantages",
    "Équipements": "Équipements",
    "Fonctionalités": "Fonctionnalités",
    "Portail client": "Portail client",
    "Plateforme de gestion": "Plateforme de gestion",
    "FAQs": "FAQs",
    "Nos Équipements": "Nos Équipements",
    "lake_page_text1": "Simple pour les municipalités, plus sécuritaire pour nos lacs",
    "Abonnement et paiements": "Abonnements et paiements",
    "lake_page_text2": "Notre borne de lavage permet de façon simple l'activation du lavage suite à une transaction ou une lecture de carte de membre.",
    "Génération du coupon de lavage": "Génération du coupon de lavage",
    "lake_page_text3": "Un coupon d'accès est transmit à l'utilisateur une fois le lavage terminé via texto ou coupon papier.",
    "Autorisation sur le lac": "Autorisation sur le lac",
    "lake_page_text4": "L'utilisateur peut ouvrir la barrière en numérisant le coupon de lavage, celui-ci permettant une mise-à-l'eau et une sortie.",
    "Plus qu'une simple solution de station de lavage": "Plus qu'une simple solution de station de lavage",
    "lake_page_text5": "Découvrez notre borne de station de lavage, un équipement ultra-robuste et résolument moderne qui révolutionne l'expérience de lavage de vos embarcations. Conçue pour offrir une utilisation intuitive, cette borne facilite le lancement de chaque lavage en toute simplicité. Grâce à notre interface personnalisable sur-mesure, vos utilisateurs bénéficient d'une flexibilité optimale. Ils peuvent choisir la pompe désirée, définir leur destination pour débloquer l'accès, et profiter d'une tarification différenciée entre résidents et non-résidents. De plus, la borne permet de sélectionner la durée du lavage en fonction de la taille de l'embarcation, assurant ainsi une expérience sur mesure adaptée à chaque besoin. Optez pour notre borne de station de lavage et offrez à vos clients une solution de lavage moderne, pratique et personnalisée, qui allie robustesse et innovation.",
    "lake_page_text6": "Le boîtier de commande assure une sécurité optimale pour l'accès au lac en régulant les ouvertures de la barrière. Sa fonction principale est de valider les preuves de lavage, garantissant ainsi que seules les embarcations ayant satisfait aux conditions de lavage peuvent accéder au lac. Grâce à ce système de contrôle rigoureux, vous pouvez être assuré que l'accès est strictement réservé aux véhicules conformes aux normes d'entretien, renforçant ainsi la protection et la propreté de votre environnement aquatique.",
    "lake_page_text7": "Nos barrières de sécurité assurent une protection efficace de l'accès au lac en empêchant les embarcations non conformes d'entrer. Conçues pour être robustes et durables, ces barrières sont fabriquées avec des matériaux industriels de haute qualité, garantissant une résistance optimale aux intempéries et aux conditions environnementales sévères. Elles offrent ainsi une sécurité fiable tout en minimisant les besoins de maintenance.",
    "lake_page_text8": "Connectée directement à notre logiciel de gestion, permet une surveillance en temps réel des opérations. Elle offre une vue constante sur l'activité, garantissant la protection de votre investissement et veillant à ce que les lavages soient réalisés conformément aux normes établies. Grâce à cette intégration fluide, vous pouvez surveiller et contrôler efficacement les opérations, tout en ayant accès à des enregistrements précis pour une gestion optimale. L'audio bidirectionnel permet une communication claire et efficace directement à travers la caméra de surveillance. Vous pouvez offrir une assistance vocale en temps réel aux utilisateurs et recevoir des retours instantanés, facilitant ainsi le support et la gestion des opérations.",
    "lake_page_text9": "Notre interface permet de gérer plusieurs stations de lavage à partir d'une seule borne, réduisant ainsi considérablement les coûts d'investissement en équipements. En centralisant la gestion et en diminuant le besoin en matériel supplémentaire, vous bénéficiez d'une solution économique tout en maintenant une efficacité maximale. Profitez de cette technologie avancée pour optimiser vos opérations et réaliser des économies substantielles.",
    "lake_page_text10": "Le choix du type d'embarcation permet de bénéficier de lavages gratuits pour les équipements tels que les kayaks et les planches de surf, qui peuvent accéder directement au lac sans passer par la barrière. Ce service comprend une preuve de lavage sans code d'accès, attestant que le nettoyage a été effectué correctement. Cette solution simplifie l'accès tout en garantissant la conformité et la propreté, offrant ainsi une commodité maximale pour vos aventures aquatiques.",
    "lake_page_text11": "Nous proposons une tarification différenciée pour offrir un rabais de courtoisie aux résidents locaux. Les résidents bénéficient de tarifs avantageux, tandis que les non-résidents se voient appliquer un tarif standard. Cette approche permet de récompenser les membres de la communauté tout en offrant un service de qualité à tous les visiteurs.",
    "lake_page_text12": "Pour garantir l'accès aux tarifs réduits réservés aux résidents, nous demandons une preuve de résidence. Celle-ci peut être fournie via le numéro de plaque d'immatriculation ou le numéro de permis de conduire. Des vérifications aléatoires seront effectuées pour confirmer la validité des informations fournies. Cette mesure assure une application équitable des réductions et contribue à maintenir la qualité et l’intégrité du service.",
    "lake_page_text13": "Pour garantir l'accès aux tarifs réduits réservés aux résidents, nous demandons une preuve de résidence. Celle-ci peut être fournie via le numéro de plaque d'immatriculation ou le numéro de permis de conduire. Des vérifications aléatoires seront effectuées pour confirmer la validité des informations fournies. Cette mesure assure une application équitable des réductions et contribue à maintenir la qualité et l’intégrité du service.",
    "lake_page_text14": "Grâce à notre interface, la sélection de la destination génère automatiquement un code d'accès spécifique pour chaque lac. Cette fonctionnalité assure un contrôle précis et sécurisé de l'accès. De plus, notre système fournit des statistiques détaillées sur l'utilisation et le trafic des lacs, vous permettant de suivre et d'analyser les tendances en temps réel. Profitez d'une gestion simplifiée et d'une meilleure compréhension de l'activité sur vos sites grâce à ces outils performants.",
    "lake_page_text15": "Permettez aux utilisateurs de sélectionner la taille de leur embarcation pour une gestion précise et personnalisée du lavage. Cette fonctionnalité aide à éviter le gaspillage d'eau en ajustant le lavage en fonction des besoins spécifiques, tout en réduisant le temps d'attente pour obtenir la preuve de lavage. Ainsi, chaque utilisateur bénéficie d'une expérience efficace et sur mesure, tout en contribuant à une utilisation plus responsable des ressources.",
    "lake_page_text16": "Notre compteur intégré vous garantit un lavage complet et efficace, en affichant clairement la progression du processus à distance. Seule une fois le compteur atteint sa fin, vous recevrez une preuve de lavage officielle, indispensable pour accéder au lac. Ce système vous assure que chaque lavage est effectué dans les règles de l'art, tout en facilitant une vérification simple et transparente.",
    "lake_page_text17": "La preuve de lavage peut être récupérée de deux manières pratiques. Vous avez le choix entre un billet papier traditionnel ou une option plus moderne : l'envoi de la preuve par message texte sur votre téléphone mobile. Cette dernière solution assure une empreinte écologique réduite tout en évitant la perte du billet. Optez pour la méthode qui vous convient le mieux et bénéficiez d'une gestion simplifiée de vos preuves de lavage.",
    "lake_page_text18": "Notre portail client vous permet de gérer facilement vos abonnements annuels pour chaque embarcation. Grâce à cette interface intuitive, vous pouvez consulter, modifier et renouveler vos abonnements en quelques clics. Profitez d'une gestion centralisée et simplifiée de vos services, tout en ayant un contrôle total sur vos informations d'abonnement.",
    "lake_page_text19": "Si votre municipalité possède plusieurs lacs, nous offrons la possibilité de s'abonner à un ou plusieurs lacs à un tarif de groupe avantageux. Cette option vous permet de bénéficier de réductions tout en ayant la flexibilité de choisir les lacs auxquels vous souhaitez accéder. Profitez d'une solution économique et adaptée à vos besoins pour explorer et profiter pleinement de tous les lacs de votre région.",
    "lake_page_text20": "La gestion de vos abonnements n'a jamais été aussi simple et efficace grâce à notre fonctionnalité de gestion des cartes de crédit. Avec notre solution innovante, vous pouvez facilement configurer une ou plusieurs cartes de crédit pour automatiser le renouvellement de vos abonnements pour vos embarcations.",
    "lake_page_text21": "Sur notre plateforme sécurisée, les transactions par carte de crédit sont simples et rapides. Grâce à notre certification PCI, nous garantissons une sécurité maximale pour vos paiements. En quelques clics seulement, vous pouvez effectuer vos achats en toute tranquillité, sachant que vos informations financières sont protégées contre toute fraude. Profitez d'une expérience de paiement fluide et sécurisée à chaque utilisation.",
    "lake_page_text22": "Notre système de gestion avancé permet un contrôle complet et à distance de vos équipements. Vous pouvez consulter l'historique des événements récents pour un suivi précis des opérations, surveiller l'état en temps réel de chaque appareil et accéder aux flux des caméras associées. Cette fonctionnalité vous offre une visibilité totale et un contrôle optimal sur l'ensemble de vos équipements, garantissant une gestion efficace et réactive.",
    "lake_page_text23": "Grâce à notre technologie d'intelligence artificielle avancée, un clip vidéo est automatiquement créé pour chaque session de lavage et chaque passage à la barrière. Ce clip fournit une preuve visuelle détaillée du lavage réalisé, assurant une transparence totale et facilitant le suivi. Cette fonctionnalité innovante optimise le processus en offrant un aperçu clair et précis des opérations, tout en simplifiant la documentation et le contrôle de la qualité.",
    "lake_page_text24": "Optimisez la gestion de vos dispositifs de surveillance avec notre fonctionnalité avancée de journal d'événements. Conçu pour offrir une visibilité complète sur chaque interaction et chaque changement lié à vos appareils, notre système vous garantit un suivi précis et une gestion proactive.",
    "lake_page_text25": "Simplifiez la gestion de votre système de surveillance avec notre fonctionnalité de carte interactive. Conçue pour vous offrir un accès rapide et intuitif à tous vos appareils, cette fonctionnalité est particulièrement pratique pour les systèmes disposant d’une longue liste de dispositifs.",
    "lake_page_text26": "Découvrez une nouvelle dimension de gestion et d'analyse avec notre fonctionnalité de statistiques et de rapports avancés. Conçue pour offrir des insights approfondis et des données exploitables, cette fonctionnalité vous permet d'optimiser la performance de votre système de surveillance et de prendre des décisions éclairées.",
    "lake_page_text27": "Découvrez la révolution de la surveillance en temps réel avec notre système de caméras à latence quasiment inexistante. Conçu pour offrir une expérience de sécurité fluide et réactive, notre système vous garantit une vigilance constante avec une précision et une rapidité inégalées.",
    "lake_page_text28": "Transformez la gestion de votre sécurité avec notre enregistreur vidéo en réseau (NVR) de haute performance. Conçu pour répondre aux exigences les plus strictes en matière de surveillance, notre NVR vous offre une solution robuste et évolutive pour enregistrer, gérer et visionner vos vidéos de manière fluide et efficace.",
    "lake_page_text29": "Découvrez la simplicité de retrouver et de consulter les événements capturés par vos caméras grâce à notre système de gestion de clips vidéo avancé. Notre solution vous offre une liste de clips parfaitement organisée pour une recherche et une consultation rapides et efficaces.",
    "lake_page_text30": "Découvrez la simplicité de suivre chaque interaction avec vos appareils grâce à notre liste de passages. Cette fonctionnalité vous permet de retracer facilement les accès et les actions effectuées sur chaque dispositif, offrant une vue claire et détaillée de chaque utilisation.",
    "lake_faq_title1": "Comment le système est-il déployé ?",
    "lake_faq_desc1": "Le déploiement de notre système se déroule en plusieurs étapes. Tout d'abord, un sous-traitant spécialisé dans l'installation de notre solution se charge de mettre en place l'équipement. Ensuite, celui-ci est connecté à notre infrastructure cloud. Cette connexion garantit non seulement que le système respecte nos normes de cybersécurité et reste toujours à jour, mais aussi qu'il fonctionne de manière optimale tout en contribuant à la réduction de vos coûts. Par ailleurs, cette configuration nous permet de surveiller l'état du système en temps réel pour assurer une performance maximale.",
    "lake_faq_title2": "J'ai déjà des barrières en place. Est-il possible de les intégrer à votre solution ?",
    "lake_faq_desc2": "Oui, il est tout à fait possible d'intégrer vos barrières existantes à notre solution. Nous avons conçu notre système pour être compatible avec une large gamme d'équipements existants. Nos experts procéderont à une évaluation de vos barrières actuelles pour s'assurer qu'elles peuvent être intégrées efficacement. Nous vous guiderons à chaque étape du processus pour garantir une intégration fluide et optimiser les performances globales de votre solution.",
    "lake_faq_title3": "Comment évitez-vous les pannes ?",
    "lake_faq_desc3": "Notre système est conçu avec plusieurs fonctionnalités pour minimiser les interruptions et assurer une continuité de service :<br>Indépendance vis-à-vis du réseau internet : Notre système est conçu pour fonctionner même en cas de panne du réseau internet. Les entrées et sorties restent opérationnelles grâce à des solutions de sauvegarde qui permettent au système de continuer à gérer les accès sans connexion internet.<br><br>Modules de secours pour les barrières : Les barrières sont équipées de modules de secours permettant leur ouverture automatique en cas de perte de courant. Cela garantit que les utilisateurs peuvent toujours sortir en toute sécurité même si une panne électrique survient.<br><br>Accès pour les services d'urgence : En cas d'urgence, nos dispositifs permettent aux services de secours d'accéder rapidement à la station et au lac, assurant une intervention efficace en cas de besoin.<br><br>Nous nous engageons à maintenir notre équipement en excellent état de fonctionnement avec des contrôles réguliers et une maintenance préventive pour éviter les pannes et garantir une expérience utilisateur fluide.",
    "lake_faq_title4": "Offrez-vous un service d'assistance pour les utilisateurs ?",
    "lake_faq_desc4": "Oui, nous offrons un service d'assistance disponible 24h/24 et 7j/7 pour vos utilisateurs. Voici comment nous vous assistons :<br><br>Assistance à distance : Notre équipe d'assistance est disponible à tout moment pour répondre à vos questions et résoudre tout problème que vous pourriez rencontrer. Vous pouvez nous contacter via nos interphones situés sur place ou en appelant notre numéro d'urgence dédié.<br><br>Techniciens formés : Nos techniciens qualifiés sont prêts à vous aider avec toutes vos préoccupations, qu'il s'agisse d'une question technique ou d'une aide pour utiliser nos installations. Leur expertise garantit une réponse rapide et efficace à vos demandes.<br><br>Nous nous engageons à offrir un soutien réactif et efficace pour assurer une expérience utilisateur fluide et sans tracas.",
    "Reprenez le contrôle sur la protection de vos lacs": "Reprenez le contrôle sur la protection de vos lacs",
    "Borne pour station de lavage": "Borne pour station de lavage",
    "Écran tactile haute définition de 10,1 pouces": "Écran tactile haute définition de 10,1 pouces",
    "Profitez d’une interface claire et conviviale pour une utilisation simplifiée et intuitive.": "Profitez d’une interface claire et conviviale pour une utilisation simplifiée et intuitive.",
    "Lecteur de carte pour membres abonnés": "Lecteur de carte pour membres abonnés",
    "Accédez facilement aux services grâce à une identification rapide et sécurisée.": "Accédez facilement aux services grâce à une identification rapide et sécurisée.",
    "Terminal de paiement nouvelle génération": "Terminal de paiement nouvelle génération",
    "Acceptez toutes les cartes de débit et de crédit avec une technologie de pointe pour des transactions fluides et sécurisées.": "Acceptez toutes les cartes de débit et de crédit avec une technologie de pointe pour des transactions fluides et sécurisées.",
    "Imprimante à reçus et preuve de lavage": "Imprimante à reçus et preuve de lavage",
    "Recevez automatiquement un reçu détaillé et une preuve de lavage pour chaque transaction.": "Recevez automatiquement un reçu détaillé et une preuve de lavage pour chaque transaction.",
    "Envoi de preuve par texto (SMS/MMS)": "Envoi de preuve par texto (SMS/MMS)",
    "Envoyez la preuve de lavage directement par message texte pour plus de commodité.": "Envoyez la preuve de lavage directement par message texte pour plus de commodité.",
    "Assistance vocale intégrée": "Assistance vocale intégrée",
    "Bénéficiez d’un support guidé et interactif grâce à une assistance vocale claire et précise.": "Bénéficiez d’un support guidé et interactif grâce à une assistance vocale claire et précise.",
    "Interphone pour support à distance": "Interphone pour soutien à distance",
    "Contactez directement le support technique en cas de besoin pour une assistance immédiate.": "Contactez directement le support technique en cas de besoin pour une assistance immédiate.",
    "Contrôle à distance via logiciel de gestion": "Contrôle à distance via logiciel de gestion",
    "Gérez et surveillez l’ensemble des opérations à distance grâce à notre logiciel de gestion complet.": "Gérez et surveillez l’ensemble des opérations à distance grâce à notre logiciel de gestion complet.",
    "Événements et statut synchronisés en temps réel": "Événements et statut synchronisés en temps réel",
    "Restez informé en permanence des événements et de l’état grâce à une synchronisation en temps réel.": "Restez informé en permanence des événements et de l’état grâce à une synchronisation en temps réel.",

    "Boitier de commande": "Boitier de commande",
    "Clavier numérique": "Clavier numérique",
    "Interface pratique et ergonomique pour une saisie rapide et facile des informations.": "Interface pratique et ergonomique pour une saisie rapide et facile des informations.",
    "Lecteur de carte pour les employés et les urgences": "Lecteur de carte pour les employés et les urgences",
    "Assure un accès sécurisé et instantané pour le personnel autorisé et les situations d'urgence.": "Assure un accès sécurisé et instantané pour le personnel autorisé et les situations d'urgence.",
    "Lecteur de code QR et code-barres": "Lecteur de code QR et code-barres",
    "Permet une identification rapide et précise des données à l'aide de codes QR et codes-barres, facilitant ainsi les transactions et les vérifications.": "Permet une identification rapide et précise des données à l'aide de codes QR et codes-barres, facilitant ainsi les transactions et les vérifications.",
    "Intercom for remote support": "Intercom for remote support",
    "Contact technical support directly if needed for immediate assistance.": "Contact technical support directly if needed for immediate assistance.",
    "Barrière de sécuriter": "Barrière de sécurité",
    "Bras de 10 à 20 pieds": "Bras de 10 à 20 pieds",
    "Conçu pour s’adapter à divers besoins d’accès, ce bras offre une flexibilité optimale pour gérer les différentes largeurs d’entrée, tout en assurant une barrière physique efficace.": "Conçu pour s’adapter à divers besoins d’accès, ce bras offre une flexibilité optimale pour gérer les différentes largeurs d’entrée, tout en assurant une barrière physique efficace.",
    "Éclairage LED intégré": "Éclairage LED intégré",
    "Équipé de lumières LED performantes, il garantit une visibilité accrue et une sécurité renforcée. L'éclairage LED est également économe en énergie, offrant une longue durée de vie et une maintenance réduite.": "Équipé de lumières LED performantes, il garantit une visibilité accrue et une sécurité renforcée. L'éclairage LED est également économe en énergie, offrant une longue durée de vie et une maintenance réduite.",
    "Ouverture automatique lors de panne de courant": "Ouverture automatique lors de panne de courant",
    "Garantit un accès sans interruption même en situation de coupure d'électricité, assurant ainsi la fluidité et la continuité de votre expérience.": "Garantit un accès sans interruption même en situation de coupure d'électricité, assurant ainsi la fluidité et la continuité de votre expérience.",
    "Caméra de surveillance": "Caméra de surveillance",

    "Multiple de station de lavage": "Multiple de station de lavage",
    "Choix du type d'embarcation": "Choix du type d'embarcation",
    "Tarification différenciée": "Tarification différenciée",
    "Preuve de résidence": "Preuve de résidence",
    "Paiement simple et rapide": "Paiement simple et rapide",
    "Multi destinations": "Multi destinations",
    "Lavage adapté": "Lavage adapté",
    "Compte à rebours": "Compte à rebours",
    "Preuve de lavage": "Preuve de lavage",
    "Gestion simplifiée des abonnements annuels": "Gestion simplifiée des abonnements annuels",
    "Abonnement à un ou plusieurs lac": "Abonnement à un ou plusieurs lacs",
    "Gestion des cartes de crédits": "Gestion des cartes de crédit",
    "Paiement simple en quelques clics": "Paiement simple en quelques clics",
    "Gestion des appareils": "Gestion des appareils",
    "Clip généré par notre intelligence artificielle": "Clip généré par notre intelligence artificielle",
    "Journal d'événements détaillé": "Journal d'événements détaillé",
    "Carte interactive": "Carte interactive",
    "Statistiques et rapports avancés": "Statistiques et rapports avancés",
    "Surveillance en temps réel des opérations": "Surveillance en temps réel des opérations",
    "NVR de Pointe": "NVR de Pointe",
    "Clip pour chaque événements": "Clip pour chaque événement",
    "Liste de passages": "Liste de passages",
    "Industry": "Industrie",
    "On-Premise solutions available": "Solutions disponibles sur site",
    "Optimized softwares": "Logiciels optimisés",
    "Ultra-fast systems": "Systèmes ultra-rapides",
    "Affordable equipments": "Équipements abordables",
    "Customized to meet needs": "Personnalisés pour répondre aux besoins",
    "Cloud solutions available": "Solutions cloud disponibles",
    "Data replications": "Réplications de données",
    "Load balancing": "Répartition de charge",
    "Daily backups": "Sauvegardes quotidiennes",
    "Secured Network": "Réseau sécurisé",
    "Certificate Number": "Numéro du certificat",
    "pci_valid_till": "Valide jusqu’au 28 février 2025",
    "monday_to_friday": "du lundi au vendredi"
};