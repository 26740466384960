// Copyright 2023 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import '@popperjs/core';
import BootstrapVue3 from 'bootstrap-vue-3'
import { createI18n } from 'vue-i18n';
//@ts-ignore
import eventBus from "vue3-eventbus"
import VueSplide from '@splidejs/vue-splide';
import LocaleEN from '@/locales/en.js';
import LocaleFR from '@/locales/fr.js';

import '../public/css/tatsu-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'lightbox2/dist/css/lightbox.min.css'
require('vue-ionicons/ionicons.css')
/* Theme variables */
import './theme/variables.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js'

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('locale') ?? 'en',
    messages: {
        "en": LocaleEN,
        "fr": LocaleFR
    }
});

// Vue.config.productionTip = false

const app = createApp(App)
.use(router)
.use(eventBus)
//@ts-ignore
.use(BootstrapVue3)
.use(VueSplide)
.use(i18n);

router.isReady().then(() => {
    app.mount('#app');
});
