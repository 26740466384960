<template>
    <section class="contact_banner">
        <div class="container">
            <b-row>
                <b-col cols="12" sm="12" md="8" lg="8" xl="8">
                    <h2 style="margin-bottom:20px;">{{ t('contact_banner_text') }}</h2>
                </b-col>
                <b-col cols="12" sm="12" md="4" lg="4" xl="4" style="display: flex; justify-content: center; align-items: center;">
                    <a href="/contact" v-on:click.prevent="goToRoute('/contact');" class="outlinebtn">{{ t('CONTACT US') }}</a>
                </b-col>
            </b-row>
        </div>
    </section>
</template>
<style scoped>
.contact_banner {
    width: 100%;
    padding: 70px 0;
    background-color: var(--lexoh-color-primary);
}
.contact_banner h2 {
    font-family: "Proxima Nova",-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    text-transform: none;
    letter-spacing: 0;
    color: var(--lexoh-color-base);
    margin-bottom: 0;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n'
export default defineComponent({
    setup()
    {
        const { t } = useI18n({
            inheritLocale: true,
            useScope: 'global'
        });
        return { t };
    },
    methods: {
        goToTop() {
            window.scroll({top: 0, left: 0});
        },
        goToRoute(routeName: any)
        {
            this.$router.push(routeName);
            this.goToTop();
            (document.activeElement as HTMLElement).blur();
            return false;
        }
    }
});
</script>