export default {
    "home": "Home",
    "solutions": "Solutions",
    "faq": "FAQ",
    "contact": "Contact",
    "Access Control Solutions": "Access Control Solutions",
    "Access Control": "Access Control",
    "Parking Solutions": "Parking Solutions",
    "Payment Systems": "Payment Systems",
    "Communication Systems": "Communication Systems",
    "Security Cameras": "Security Cameras",
    "Protection of Lakes": "Protection of Lakes",
    "footer_desc": "We provide next generation software solutions for parking and access control. Our goal is to provide top quality features that are intuitive to use.",
    "Monday": "Monday",
    "Friday": "Friday",
    "Work Hours": "Work Hours",
    "footer_support": "Our Support and Sales team is available to answer your queries.",
    "CALL US TODAY": "CALL US TODAY",
    "Quick Links": "Quick Links",
    "Legal Links": "Legal Links",
    "Terms of service": "Terms of service",
    "Privacy policy": "Privacy policy",
    "Cookie policy": "Cookie policy",
    "All Rights Reserved": "All Rights Reserved",
    "OUR PARTNERS": "OUR PARTNERS",
    "THEY LOVE TO DO BUSINESS WITH US": "THEY LOVE TO DO BUSINESS WITH US",
    "contact_banner_text": "Are you looking for top experts and technology?",
    "CONTACT US": "CONTACT US",
    "home_hero_title": "Next generation software solutions for access control",
    "home_hero_desc": "Unparalleled quality solutions tailored for your needs to reduce costs and time waste. Our goal is to provide top quality features that are intuitive to use.",
    "Learn more": "Learn more",
    "WHY CHOOSE US?": "WHY CHOOSE US?",
    "SECURITY EXPERTS": "SECURITY EXPERTS",
    "why_choose_us_desc1": "At LEXOH, we specialize in comprehensive security solutions that encompass both digital and physical realms. With a dedicated team of professionals, we are committed to safeguarding your assets and environments using cutting-edge technologies aligned with the highest security standards.",
    "why_choose_us_desc2": "Backed by years of experience, we have honed our expertise in the intricate domains of cybersecurity and real-world security implementation. Our solutions are not just products; they are strategic tools designed to enhance your security posture.",
    "ABOUT US": "ABOUT US",
    "Driven by Vision, Defined by Values": "Driven by Vision, Defined by Values",
    "about_us_desc": "We are a renowned hub of professional software engineers and field technicians. With a combined experience exceeding four decades in the parking and access control industry, we embarked on a journey to establish our pioneering startup. Our unwavering mission is to herald unparalleled software solutions that transcend conventional industry standards, all while remaining firmly anchored in our core principles.",
    "OUR SERVICES": "OUR SERVICES",
    "TRANSFORMING NEEDS INTO SOLUTIONS": "TRANSFORMING NEEDS INTO SOLUTIONS",
    "service_1_desc": "Security is a key priority for businesses of all types and sizes. Protecting your business assets is crucial, especially if you deal with expensive equipment and confidential information.",
    "service_2_desc": "Parking management is a great solution to generate passive income or secure who is allowed to enter on your park site.<br/><br/><br/>",
    "service_3_desc": "Security cameras give you extra security, deter people from committing crimes and give you the evidence you need for your insurance.<br/><br/>",
    "service_4_desc": "Preserving our lakes’ purity, one wash at a time. Using washing stations with cutting-edge barriers and access controls to guard against contamination. Protecting waters, safeguarding futures.",
    "service_5_desc": "Elevate your communication with our advanced intercom systems powered by SIP technology. We bridge the gap between you and your valued users, ensuring crystal-clear interactions.",
    "service_6_desc": "Future of hassle-free transactions with our Payment Systems. From streamlined parking kiosks to convenient washing stations, empower your customers with easy and secure self-service payment solutions.",
    "IDEATION TO OPERATIONS": "IDEATION TO OPERATIONS",
    "WHAT MAKES US DIFFERENT": "WHAT MAKES US DIFFERENT",
    "makes_us_different_desc1": "At LEXOH, what sets us apart is our unwavering passion as software engineers dedicated to crafting unparalleled solutions for our industry. Our commitment to excellence drives us to develop software that stands head and shoulders above the rest. Every line of code is a testament to our pursuit of robustness and speed, meticulously crafted using native programming languages and cutting-edge technologies. We embrace the agile methodology, fostering collaboration, adaptability, and rapid progress. With every project, we strive for nothing less than the extraordinary – delivering software that not only meets but exceeds expectations, setting new standards for innovation and performance.",
    "makes_us_different_desc2": "We understand that technology should be as intuitive as it is powerful. That’s why we go the extra mile to ensure that every component we design is user-friendly, whether accessed from a desktop or a mobile device. Our user-centric approach permeates every aspect of our software development process.",
    "Gallery": "Gallery",
    "Modular equipment": "Modular equipment",
    "modular_equipment_desc": "We make sure all our equipment stay modular so you can bring your own and upgrade at anytime.",
    "modular_equipment_title1": "HD Screen Display",
    "modular_equipment_title2": "Ultra-High Frequency RFID Reader",
    "modular_equipment_title3": "2D Barcode/QR Reader",
    "modular_equipment_title4": "NFC/HID Card Reader",
    "modular_equipment_title5": "Payment Terminal",
    "modular_equipment_title6": "SIP Intercom",
    "modular_equipment_title7": "Security Camera",
    "modular_equipment_title8": "Magnetic door Lock",
    "modular_equipment_desc1": "We pair our gate controller with a 1080p HD display, no more monochrome pixelated LCD display.",
    "modular_equipment_desc2": "Add an RFID reader to your infrastructure and start identifying vehicles without any human interaction using a transponder.",
    "modular_equipment_desc3": "Even though it is an old technology, it has been proven to work well and users are used to it. So you can scan your printed coupons/tickets.",
    "modular_equipment_desc4": "Contact less entrances and exits using an access card is a fast and easy way to identify users.",
    "modular_equipment_desc5": "We use next generation android based payment terminal with a custom application allowing much more than traditional terminals.",
    "modular_equipment_desc6": "Intercoms are useful they can give your users instant support and a way to remotely open a door/gate without giving your employee full management access.",
    "modular_equipment_desc7": "Protect your investment with security cameras from vandals or accidents and be free from liability.",
    "modular_equipment_desc8": "Magnetic door locks use electromagnetic energy to secure entry and exit doors, they can be used to restrict employees and users by zoning.",
    "acces_control_title": "ACCESS CONTROL<br/>SOLUTIONS",
    "acces_control_title2": "RESIDENTIAL & COMMERCIAL SOLUTIONS",
    "Elevating Access Control": "Elevating Access Control",
    "acces_control_desc1": "Our objective is to provide top-quality features that are intuitively user-friendly. We invest in research and development to offer technology-driven solutions at the forefront, thereby delivering a seamless and enjoyable user experience.",
    "acces_control_desc2": "For residential spaces, our tailored solutions ensure that homeowners and residents experience a heightened sense of safety and convenience. From smart entry systems that enable remote access management to advanced surveillance integrations, we provide a seamless blend of technology and peace of mind.",
    "acces_control_desc3": "In the realm of commercial spaces, our solutions redefine access control by seamlessly harmonizing with the pace and demands of modern businesses. Whether it’s optimizing employee access, visitor management, or safeguarding sensitive areas, our suite of solutions is designed to adapt and enhance security protocols with an eye towards operational fluidity.",
    "acces_control_feature_title1": "User-Centric Design",
    "acces_control_feature_title2": "Cutting-Edge Technology",
    "acces_control_feature_title3": "Enhanced Security",
    "acces_control_feature_title4": "Customized Adaptability",
    "acces_control_feature_title5": "Remote Accessibility",
    "acces_control_feature_desc1": "Our solution is crafted with the user in mind, prioritizing intuitive interfaces and ease of use, ensuring a smooth experience for all users.",
    "acces_control_feature_desc2": "We constantly push the boundaries of technology, incorporating the latest advancements to ensure our solution remains at the forefront of innovation.",
    "acces_control_feature_desc3": "Our solution integrates advanced security features to safeguard residential and commercial spaces, offering peace of mind and protection against potential threats.",
    "acces_control_feature_desc4": "From residential homes to complex commercial environments, our solution adapts seamlessly, offering customizable configurations to match unique needs.",
    "acces_control_feature_desc5": "Enjoy the convenience of managing and monitoring access remotely, giving you control at your fingertips, wherever you are.",
    "parking_solutions_title": "PARKING<br/>SOLUTIONS",
    "parking_solutions_title2": "OUR MISSION & OUR VISION",
    "Stress-free solutions.": "Stress-free solutions.",
    "parking_solutions_desc": "Companies save valuable administrative time and make better decisions when they have the right information in real time. That’s why at Lexoh, we don’t just plan to make equal new products, but rather make products that are well thought out and designed to be easy to use.",
    "parking_solutions_feature_title1": "Take management remote with mobile-first capabilities while cloud-based reliability maintains a consistent experience for your customers.",
    "parking_solutions_feature_title2": "Deliver a high-tech, touchless experience that’s fit for the 21st-century lifestyle of your residents and their guests.",
    "parking_solutions_feature_title3": "Create a sense of ease and efficiency with our simple approach to high-volume operational controls and revenue accountability.",
    "parking_solutions_feature_title4": "Gain a birds-eye view of operations across your college campus with comprehensive controls that streamline management and offer anytime, anywhere accessibility.",
    "parking_solutions_feature_desc1": "",
    "parking_solutions_feature_desc2": "",
    "parking_solutions_feature_desc3": "",
    "parking_solutions_feature_desc4": "",
    "payment_systems_title": "PAYMENT<br/>SYSTEMS",
    "payment_systems_title2": "INNOVATION EVEN WITH PAYMENTS",
    "Unprecedented features": "Unprecedented features",
    "payment_systems_desc": "We are proud to be industry pioneers in offering a next-generation payment terminal solution for parking. Through our commitment to excellence and our drive to push boundaries, we have revolutionized the sector. By reducing costs and expanding the range of features, we have transformed the landscape of parking solutions.",
    "payment_systems_feature_title1": "PIN-based Identification",
    "payment_systems_feature_title2": "QR/Barcode Identification",
    "payment_systems_feature_title3": "Credit/Debit Card Identification",
    "payment_systems_feature_title4": "Subscription Renewal",
    "payment_systems_feature_title5": "Weekly/Monthly Pass Purchase",
    "payment_systems_feature_desc1": "Users can enter an identification number for access.",
    "payment_systems_feature_desc2": "Our terminal supports QR code and barcode scanning for swift and precise identification.",
    "payment_systems_feature_desc3": "Users can effortlessly utilize their credit or debit cards for seamless identification, reducing paper usage.",
    "payment_systems_feature_desc4": "Our terminal offers the practical option to renew existing subscriptions.",
    "payment_systems_feature_desc5": "Users have the flexibility to acquire weekly or monthly parking passes.",
    "communication_systems_title": "COMMUNICATION<br/>SYSTEMS",
    "communication_systems_title2": "CONNECTING SECURITY, ENHANCING",
    "Advanced Intercom Solutions": "Advanced Intercom Solutions",
    "communication_systems_desc1": "An intercom system is an essential tool to ensure the security and efficient management of parking facilities. With the increasing number of vehicles and users in parking areas, having a reliable communication system becomes crucial. This system allows users to request assistance when needed and enables operators to manage entries and exits seamlessly. Our parking intercom system boasts advanced features that position it at the forefront of the industry.",
    "communication_systems_desc2": "We are dedicated to delivering a comprehensive solution that addresses today’s security and access control and parking management needs. By selecting our intercom system, you are opting for a top-tier communication and security solution. Our commitment lies in providing superior-quality products that meet the demands of modern security and parking management. Trust in our expertise and benefit from a complete and reliable solution for your parking facility.",
    "communication_systems_feature_title1": "Clear and Reliable Communication",
    "communication_systems_feature_title2": "Integration with Other Security Systems",
    "communication_systems_feature_title3": "Advanced Access Control",
    "communication_systems_feature_title4": "Ease of Installation and Use",
    "communication_systems_feature_title5": "Reliability and Durability",
    "communication_systems_feature_desc1": "",
    "communication_systems_feature_desc2": "",
    "communication_systems_feature_desc3": "",
    "communication_systems_feature_desc4": "",
    "communication_systems_feature_desc5": "",
    "security_cameras_title": "SECURITY<br/>CAMERAS",
    "security_cameras_title2": "GUARDING EVERY ANGLE, SECURING EVERY MOMENT",
    "Security with Surveillance Cameras": "Security with Surveillance Cameras",
    "security_cameras_desc1": "Step into a realm of unmatched security and vigilance with our advanced surveillance camera solutions. At LEXOH, we understand that safeguarding your premises is paramount, whether it’s your home or business. Our state-of-the-art security cameras offer unparalleled clarity, coverage, and peace of mind. With real-time monitoring and high-resolution recording capabilities, you can trust that our cameras serve as an ever-watchful eye, deterring potential threats and providing valuable insights when they matter most. Elevate your security measures with technology that doesn’t just record moments – it ensures your safety, day and night.",
    "security_cameras_desc2": "Beyond mere recording, our surveillance camera systems are designed to empower you with real-time insights and control. With remote access capabilities, you can monitor your property from anywhere, at any time. Gain a sense of confidence and stay connected to your surroundings, whether you’re at work, on vacation, or simply away from your property. Our commitment to your security extends beyond hardware – it’s about providing you with a comprehensive security ecosystem that brings you peace of mind and unparalleled protection.",
    "security_cameras_feature_title1": "Revolutionizing Access Control: ALPR Technology",
    "security_cameras_feature_title2": "AI-Powered Surveillance",
    "security_cameras_feature_desc1": "Experience the future of access control with our Automated License Plate Recognition (ALPR) technology.",
    "security_cameras_feature_desc2": "Our advanced technology not only offers real-time identification but also detects key moments, providing you with a comprehensive understanding of activities within your premises.",
    "lake_protection_title": "PROTECTION<br/>OF LAKES",
    "lake_protection_title2": "PROUD TO CONTRIBUTE IN PROTECTING THE ENVIRONMENT",
    "Preventing Lakes Contamination": "Preventing Lakes Contamination",
    "lake_protection_desc1": "At LEXOH, we take pride in contributing to environmental preservation through our revolutionary boat washing station solution. With a profound commitment to preventing cross-lake contamination, we have developed a cutting-edge approach that not only ensures the cleanliness of boats but also safeguards the delicate balance of our water ecosystems.",
    "lake_protection_desc2": "Our boat washing station solution goes beyond the traditional concept of boat maintenance. By requiring users to wash their boats before accessing the water, we effectively mitigate the risks of introducing harmful contaminants, invasive species, and pollutants into our lakes and rivers. This proactive measure acts as a powerful defense against the potential ecological disruption caused by unchecked watercraft.",
    "lake_protection_desc3": "With our innovative system, boat owners are not only granted convenient access to aquatic adventures but are also empowered as stewards of our natural resources. The simple act of boat washing becomes an essential step in preserving the purity of our water bodies, fostering a harmonious coexistence between recreation and environmental sustainability.",
    "lake_protection_desc4": "Join us in our mission to protect and nurture our precious waterways. By embracing our boat washing station solution, you’re making a tangible difference in the fight against cross-lake contamination, ensuring that the beauty and vitality of our aquatic ecosystems endure for generations to come.",
    "faq_title1": "What is LEXOH Engine? ",
    "faq_title2": "Can I transition my existing parking infrastructure to LEXOH solutions? ",
    "faq_title3": "Can I use my own RFID cards with LEXOH? ",
    "faq_desc1": "The LEXOH Engine constitutes the fundamental framework of our access control system, catering to a wide spectrum of applications encompassing commercial, residential access control, and parking management. This versatile engine empowers you to meticulously administer your user base and clientele, exercising nuanced control over their access privileges across distinct zones, all while affording the flexibility of personalized pricing structures.",
    "faq_desc2": "Our system seamlessly integrates with a wide array of barriers, readers, and intercoms, ensuring compatibility across diverse setups. However, there might be a necessity to update payment station components. Please don’t hesitate to reach out to us to arrange an on-site visit. This will enable us to delve deeper into your specific requirements and facilitate the development of a comprehensive migration solution tailored to your needs.",
    "faq_desc3": "The LEXOH Engine is integrated with MIFARE Classic 1K cards decoding, fortified by a proprietary encryption algorithm that enhances security measures. Our commitment to safeguarding your access control is exemplified through this specialized encryption approach.<br/><br/>Should you require alignment between your existing access cards and our encryption protocols, we offer the service of reprogramming. For comprehensive information, please feel free to get in touch with us via our provided contact details. Your security is our priority.",
    "Our support team will assist you with any questions.": "Our support team will assist<br/>you with any questions.",
    "Telephone": "Telephone",
    "Address": "Address",
    "TERMS OF SERVICE": "TERMS OF SERVICE",
    "PRIVACY POLICY": "PRIVACY POLICY",
    "OUR CUSTOMERS": "OUR CUSTOMERS",
    "THEY TRUST LEXOH": "THEY TRUST LEXOH",
    "products": "Products",
    "Cloud-based": "Cloud-based",
    "Intercoms": "Intercoms",
    "Video Security": "Video Security",
    "Barriers": "Barriers",
    "Housings & Kiosks": "Housings & Kiosks",
    "Overview": "Overview",
    "Platform": "Platform",
    "Features": "Features",
    "Integration": "Integration",
    "Security": "Security",
    "Mobile": "Mobile",
    "High Availability": "High Availability",
    "Backups": "Backups",
    "Video on Demand": "Video on Demand",
    "Data retention": "Data retention",
    "Door Controllers": "Door Controllers",
    "RFID Card Reader": "RFID Card Reader",
    "QR Reader": "QR Reader",
    "Garage Door Controller": "Garage Door Controller",
    "Barrier Controller": "Barrier Controller",
    "Numeric pinpad": "Numeric pinpad",
    "Kiosk & Door Intercom": "Kiosk & Door Intercom",
    "SIP Server": "SIP Server",
    "Cameras": "Cameras",
    "Cloud camera Server": "Cloud camera Server",
    "AI vehicle & person detection": "AI vehicle & person detection",
    "Event tracking": "Event tracking",
    "Facial recognition": "Facial recognition",
    "Lane Barrier": "Lane Barrier",
    "Articulated Lane Barrier": "Articulated Lane Barrier",
    "Double Lane Barrier": "Double Lane Barrier",
    "Command Box": "Command Box",
    "Payment Box": "Payment Box",
    "Kiosk": "Kiosk",
    "AI": "AI",

    "lake_page_subtitle": "We are proud to contribute to environmental preservation with our revolutionary boat wash station solution.",
    "Avantages": "Benefits",
    "Équipements": "Equipment",
    "Fonctionalités": "Features",
    "Portail client": "Client Portal",
    "Plateforme de gestion": "Management Platform",
    "FAQs": "FAQs",
    "Nos Équipements": "Our Equipment",
    "lake_page_text1": "Simple for municipalities, safer for our lakes",
    "Abonnement et paiements": "Subscription and Payments",
    "lake_page_text2": "Our wash station allows for simple activation of the wash after a transaction or member card scan.",
    "Génération du coupon de lavage": "Wash Coupon Generation",
    "lake_page_text3": "An access coupon is sent to the user once the wash is complete via text or paper coupon.",
    "Autorisation sur le lac": "Lake Authorization",
    "lake_page_text4": "The user can open the barrier by scanning the wash coupon, allowing both launching and exit.",
    "Plus qu'une simple solution de station de lavage": "More than just a wash station solution",
    "lake_page_text5": "Discover our wash station, an ultra-robust and modern piece of equipment that revolutionizes the washing experience for your boats. Designed for intuitive use, this station simplifies the initiation of each wash. With our customizable interface, your users enjoy optimal flexibility. They can select the desired pump, define their destination to unlock access, and benefit from differentiated pricing between residents and non-residents. Additionally, the station allows users to select the wash duration based on the boat size, ensuring a tailored experience for every need. Choose our wash station and provide your clients with a modern, practical, and personalized washing solution that combines robustness and innovation.",
    "lake_page_text6": "The control unit ensures optimal security for lake access by regulating barrier openings. Its main function is to validate wash proofs, ensuring that only boats that meet the wash conditions can access the lake. This rigorous control system ensures that access is strictly reserved for vehicles compliant with maintenance standards, thus enhancing the protection and cleanliness of your aquatic environment.",
    "lake_page_text7": "Our security barriers ensure effective protection of lake access by preventing non-compliant boats from entering. Designed to be robust and durable, these barriers are made with high-quality industrial materials, guaranteeing optimal resistance to weather and harsh environmental conditions. They provide reliable security while minimizing maintenance needs.",
    "lake_page_text8": "Directly connected to our management software, it allows real-time monitoring of operations. It offers a constant view of activity, ensuring the protection of your investment and making sure washes are performed according to established standards. With this seamless integration, you can effectively monitor and control operations while having access to accurate records for optimal management. Bidirectional audio allows clear and effective communication directly through the surveillance camera. You can offer real-time voice assistance to users and receive instant feedback, facilitating support and operation management.",
    "lake_page_text9": "Our interface allows you to manage multiple wash stations from a single unit, significantly reducing equipment investment costs. By centralizing management and decreasing the need for additional hardware, you benefit from a cost-effective solution while maintaining maximum efficiency. Take advantage of this advanced technology to optimize your operations and achieve substantial savings.",
    "lake_page_text10": "The choice of boat type allows for free washes for equipment such as kayaks and surfboards, which can access the lake directly without passing through the barrier. This service includes a wash proof without an access code, certifying that the cleaning was performed correctly. This solution simplifies access while ensuring compliance and cleanliness, providing maximum convenience for your aquatic adventures.",
    "lake_page_text11": "We offer differentiated pricing to provide a courtesy discount to local residents. Residents benefit from advantageous rates, while non-residents are charged a standard rate. This approach rewards community members while providing quality service to all visitors.",
    "lake_page_text12": "To ensure access to discounted rates reserved for residents, we require proof of residence. This can be provided via the license plate number or driver's license number. Random checks will be performed to confirm the validity of the provided information. This measure ensures fair application of discounts and helps maintain the quality and integrity of the service.",
    "lake_page_text13": "To ensure access to discounted rates reserved for residents, we require proof of residence. This can be provided via the license plate number or driver's license number. Random checks will be performed to confirm the validity of the provided information. This measure ensures fair application of discounts and helps maintain the quality and integrity of the service.",
    "lake_page_text14": "Through our interface, selecting the destination automatically generates a specific access code for each lake. This feature ensures precise and secure access control. Additionally, our system provides detailed statistics on lake usage and traffic, allowing you to track and analyze trends in real time. Enjoy simplified management and a better understanding of activity on your sites with these powerful tools.",
    "lake_page_text15": "Allow users to select their boat size for precise and personalized wash management. This feature helps avoid water waste by adjusting the wash based on specific needs while reducing wait times to obtain the wash proof. Thus, each user benefits from an efficient and customized experience while contributing to more responsible resource use.",
    "lake_page_text16": "Our integrated meter ensures a complete and effective wash by clearly displaying the progress of the process from a distance. Only once the meter reaches its end will you receive an official wash proof, essential for accessing the lake. This system ensures that each wash is performed according to the standards, while facilitating simple and transparent verification.",
    "lake_page_text17": "The wash proof can be retrieved in two convenient ways. You can choose between a traditional paper ticket or a more modern option: receiving the proof via text message on your mobile phone. The latter solution ensures a reduced ecological footprint while avoiding ticket loss. Choose the method that suits you best and benefit from simplified management of your wash proofs.",
    "lake_page_text18": "Our client portal allows you to easily manage your annual subscriptions for each boat. With this intuitive interface, you can view, modify, and renew your subscriptions in a few clicks. Enjoy centralized and simplified management of your services while having complete control over your subscription information.",
    "lake_page_text19": "If your municipality has several lakes, we offer the possibility to subscribe to one or more lakes at a group rate. This option allows you to benefit from discounts while having the flexibility to choose which lakes you want to access. Enjoy a cost-effective solution tailored to your needs to explore and fully enjoy all the lakes in your area.",
    "lake_page_text20": "Managing your subscriptions has never been easier and more efficient with our credit card management feature. With our innovative solution, you can easily set up one or more credit cards to automate the renewal of your subscriptions for your boats.",
    "lake_page_text21": "On our secure platform, credit card payments are quick and easy. With our PCI certification, we ensure the highest level of security for your transactions. In just a few clicks, you can make purchases with peace of mind, knowing that your financial information is protected against fraud. Enjoy a seamless and safe payment experience every time.",
    "lake_page_text22": "Our advanced management system allows complete and remote control of your equipment. You can view the history of recent events for accurate operation tracking, monitor the real-time status of each device, and access the associated camera feeds. This feature provides you with total visibility and optimal control over all your equipment, ensuring effective and responsive management.",
    "lake_page_text23": "With our advanced artificial intelligence technology, a video clip is automatically created for each wash session and each barrier pass. This clip provides a detailed visual proof of the wash performed, ensuring total transparency and facilitating tracking. This innovative feature optimizes the process by offering a clear and precise overview of operations while simplifying documentation and quality control.",
    "lake_page_text24": "Optimize the management of your surveillance devices with our advanced event log feature. Designed to provide complete visibility into every interaction and change related to your devices, our system ensures precise tracking and proactive management.",
    "lake_page_text25": "Simplify the management of your surveillance system with our interactive map feature. Designed to offer quick and intuitive access to all your devices, this feature is particularly useful for systems with a long list of devices.",
    "lake_page_text26": "Discover a new dimension of management and analysis with our advanced statistics and reporting feature. Designed to provide in-depth insights and actionable data, this feature allows you to optimize your surveillance system's performance and make informed decisions.",
    "lake_page_text27": "Experience real-time surveillance with our nearly latency-free camera system. Designed to provide a smooth and responsive security experience, our system guarantees constant vigilance with unparalleled accuracy and speed.",
    "lake_page_text28": "Transform your security management with our high-performance network video recorder (NVR). Designed to meet the highest surveillance standards, our NVR provides a robust and scalable solution for recording, managing, and viewing your videos smoothly and efficiently.",
    "lake_page_text29": "Experience the simplicity of finding and viewing events captured by your cameras with our advanced video clip management system. Our solution offers a perfectly organized list of clips for quick and efficient searching and viewing.",
    "lake_page_text30": "Discover the simplicity of tracking each interaction with your devices with our Passage List. This feature allows you to easily trace access and actions performed on each device, providing a clear and detailed view of each usage.",
    "lake_faq_title1": "How is the system deployed?",
    "lake_faq_desc1": "The deployment of our system takes place in several stages. First, a subcontractor specialized in installing our solution handles the setup of the equipment. Next, it is connected to our cloud infrastructure. This connection ensures that the system meets our cybersecurity standards, stays up-to-date, and operates optimally while helping to reduce your costs. Additionally, this setup allows us to monitor the system's status in real-time to ensure maximum performance.",
    "lake_faq_title2": "I already have barriers in place. Is it possible to integrate them into your solution?",
    "lake_faq_desc2": "Yes, it is entirely possible to integrate your existing barriers into our solution. We have designed our system to be compatible with a wide range of existing equipment. Our experts will evaluate your current barriers to ensure they can be integrated effectively. We will guide you through every step of the process to ensure a smooth integration and optimize the overall performance of your solution.",
    "lake_faq_title3": "How do you prevent outages?",
    "lake_faq_desc3": "Our system is designed with several features to minimize interruptions and ensure continuity of service:<br>Independence from the internet: Our system is designed to function even if the internet network fails. Entries and exits remain operational thanks to backup solutions that allow the system to continue managing access without an internet connection.<br><br>Backup modules for barriers: The barriers are equipped with backup modules that allow them to open automatically in case of a power outage. This ensures that users can always exit safely even if an electrical failure occurs.<br><br>Emergency access: In case of an emergency, our devices allow rescue services to quickly access the station and the lake, ensuring effective intervention when needed.<br><br>We are committed to keeping our equipment in excellent working condition with regular checks and preventive maintenance to avoid outages and ensure a smooth user experience.",
    "lake_faq_title4": "Do you offer user support?",
    "lake_faq_desc4": "Yes, we offer 24/7 support for your users. Here's how we assist you:<br><br>Remote assistance: Our support team is available at any time to answer your questions and resolve any issues you may encounter. You can contact us via the intercoms on site or by calling our dedicated emergency number.<br><br>Trained technicians: Our qualified technicians are ready to assist you with any concerns, whether it's a technical question or help using our facilities. Their expertise ensures a quick and effective response to your requests.<br><br>We are committed to providing responsive and effective support to ensure a smooth and hassle-free user experience.",
    "Reprenez le contrôle sur la protection de vos lacs": "Take control of protecting your lakes",
    "Borne pour station de lavage": "Wash station kiosk",
    "Écran tactile haute définition de 10,1 pouces": "10.1-inch high-definition touchscreen",
    "Profitez d’une interface claire et conviviale pour une utilisation simplifiée et intuitive.": "Enjoy a clear and user-friendly interface for simplified and intuitive use.",
    "Lecteur de carte pour membres abonnés": "Card reader for subscribed members",
    "Accédez facilement aux services grâce à une identification rapide et sécurisée.": "Easily access services with quick and secure identification.",
    "Terminal de paiement nouvelle génération": "Next-generation payment terminal",
    "Acceptez toutes les cartes de débit et de crédit avec une technologie de pointe pour des transactions fluides et sécurisées.": "Accept all debit and credit cards with cutting-edge technology for smooth and secure transactions.",
    "Imprimante à reçus et preuve de lavage": "Receipt and wash proof printer",
    "Recevez automatiquement un reçu détaillé et une preuve de lavage pour chaque transaction.": "Automatically receive a detailed receipt and proof of wash for each transaction.",
    "Envoi de preuve par texto (SMS/MMS)": "Proof sent via text (SMS/MMS)",
    "Envoyez la preuve de lavage directement par message texte pour plus de commodité.": "Send proof of wash directly via text message for added convenience.",
    "Assistance vocale intégrée": "Integrated voice assistance",
    "Bénéficiez d’un support guidé et interactif grâce à une assistance vocale claire et précise.": "Benefit from guided and interactive support with clear and precise voice assistance.",
    "Interphone pour support à distance": "Intercom for remote support",
    "Contactez directement le support technique en cas de besoin pour une assistance immédiate.": "Contact technical support directly if needed for immediate assistance.",
    "Contrôle à distance via logiciel de gestion": "Remote control via management software",
    "Gérez et surveillez l’ensemble des opérations à distance grâce à notre logiciel de gestion complet.": "Manage and monitor all operations remotely with our comprehensive management software.",
    "Événements et statut synchronisés en temps réel": "Real-time synchronized events and status",
    "Restez informé en permanence des événements et de l’état grâce à une synchronisation en temps réel.": "Stay constantly informed about events and the status with real-time synchronization.",
    "Boitier de commande": "Control box",
    "Clavier numérique": "Numeric keypad",
    "Interface pratique et ergonomique pour une saisie rapide et facile des informations.": "Practical and ergonomic interface for quick and easy data entry.",
    "Lecteur de carte pour les employés et les urgences": "Card reader for employees and emergencies",
    "Assure un accès sécurisé et instantané pour le personnel autorisé et les situations d'urgence.": "Ensures secure and instant access for authorized personnel and emergency situations.",
    "Lecteur de code QR et code-barres": "QR code and barcode reader",
    "Permet une identification rapide et précise des données à l'aide de codes QR et codes-barres, facilitant ainsi les transactions et les vérifications.": "Allows for quick and accurate data identification using QR codes and barcodes, facilitating transactions and verifications.",
    "Intercom for remote support": "Intercom for remote support",
    "Contact technical support directly if needed for immediate assistance.": "Contact technical support directly if needed for immediate assistance.",
    "Barrière de sécuriter": "Security barrier",
    "Bras de 10 à 20 pieds": "10 to 20-foot arm",
    "Conçu pour s’adapter à divers besoins d’accès, ce bras offre une flexibilité optimale pour gérer les différentes largeurs d’entrée, tout en assurant une barrière physique efficace.": "Designed to accommodate various access needs, this arm provides optimal flexibility to handle different entry widths while ensuring an effective physical barrier.",
    "Éclairage LED intégré": "Integrated LED lighting",
    "Équipé de lumières LED performantes, il garantit une visibilité accrue et une sécurité renforcée. L'éclairage LED est également économe en énergie, offrant une longue durée de vie et une maintenance réduite.": "Equipped with high-performance LED lights, it ensures enhanced visibility and improved security. The LED lighting is also energy-efficient, providing a long lifespan and reduced maintenance.",
    "Ouverture automatique lors de panne de courant": "Automatic opening during power outage",
    "Garantit un accès sans interruption même en situation de coupure d'électricité, assurant ainsi la fluidité et la continuité de votre expérience.": "Guarantees uninterrupted access even during a power outage, ensuring smooth and continuous operation.",
    "Caméra de surveillance": "Surveillance camera",
    "Multiple de station de lavage": "Multiple washing station",
    "Choix du type d'embarcation": "Choice of boat type",
    "Tarification différenciée": "Differentiated pricing",
    "Preuve de résidence": "Proof of residence",
    "Paiement simple et rapide": "Simple and fast payment",
    "Multi destinations": "Multi destinations",
    "Lavage adapté": "Tailored washing",
    "Compte à rebours": "Countdown",
    "Preuve de lavage": "Proof of washing",
    "Gestion simplifiée des abonnements annuels": "Simplified annual subscription management",
    "Abonnement à un ou plusieurs lac": "Subscription to one or more lakes",
    "Gestion des cartes de crédits": "Credit card management",
    "Paiement simple en quelques clics": "Easy payment in a few clicks",
    "Gestion des appareils": "Device management",
    "Clip généré par notre intelligence artificielle": "Clip generated by our artificial intelligence",
    "Journal d'événements détaillé": "Detailed event log",
    "Carte interactive": "Interactive map",
    "Statistiques et rapports avancés": "Advanced statistics and reports",
    "Surveillance en temps réel des opérations": "Real-time operations monitoring",
    "NVR de Pointe": "High-performance NVR",
    "Clip pour chaque événements": "Clip for each event",
    "Liste de passages": "Passage list",
    "Industry": "Industry",
    "On-Premise solutions available": "On-Premise solutions available",
    "Optimized softwares": "Optimized softwares",
    "Ultra-fast systems": "Ultra-fast systems",
    "Affordable equipments": "Affordable equipments",
    "Customized to meet needs": "Customized to meet needs",
    "Cloud solutions available": "Cloud solutions available",
    "Data replications": "Data replications",
    "Load balancing": "Load balancing",
    "Daily backups": "Daily backups",
    "Secured Network": "Secured Network",
    "Certificate Number": "Certificate number",
    "pci_valid_till": "Valid Till: 28 February, 2025",
    "monday_to_friday": "Monday to Friday"
};