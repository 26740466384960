import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2201bb41"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact_banner" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { style: {"margin-bottom":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "8",
            lg: "8",
            xl: "8"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('contact_banner_text')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "12",
            md: "4",
            lg: "4",
            xl: "4",
            style: {"display":"flex","justify-content":"center","align-items":"center"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: "/contact",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => {_ctx.goToRoute('/contact');}, ["prevent"])),
                class: "outlinebtn"
              }, _toDisplayString(_ctx.t('CONTACT US')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}