import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb40a962"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "our_partners"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { style: {"width":"100%","display":"flex","align-items":"center","justify-content":"center","flex-direction":"column"} }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "our_partners" }
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { style: {"width":"100%","display":"flex","align-items":"center","justify-content":"center","flex-direction":"column"} }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "subtitle" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    false
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('OUR PARTNERS')), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('THEY LOVE TO DO BUSINESS WITH US')), 1)
            ]),
            _createVNode(_component_Splide, {
              "aria-label": "",
              options: _ctx.splideOptions
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, (item, index) => {
                  return (_openBlock(), _createBlock(_component_SplideSlide, { key: index }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: item,
                        alt: ""
                      }, null, 8, _hoisted_6)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["options"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('OUR CUSTOMERS')), 1),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.t('THEY TRUST LEXOH')), 1)
        ]),
        _createVNode(_component_Splide, {
          "aria-label": "",
          options: _ctx.splideOptions2
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (item, index) => {
              return (_openBlock(), _createBlock(_component_SplideSlide, { key: index }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: item,
                    alt: ""
                  }, null, 8, _hoisted_12)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["options"])
      ])
    ])
  ], 64))
}